import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, paragraphOne, paragraphTwo, paragraphThree, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Title title="Dr. Rosmarie Katrin Neumann" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={700} distance="30px">
              <div className="about-wrapper__image">
                <AboutImg alt="Picture of Rosmarie Neumann" filename={img} />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">
                  I have more than 15 years of interdisciplinary expertise, split between research
                  and practice. Originally trained as a Landscape Ecologist I have studied in
                  Germany, Iceland, New Zealand and the UK. My recent research sheds light on
                  knowledge uptake for decision-making, stakeholder communication networks and
                  effective factors for successful long-term networking. During my studies I have
                  worked with multiple stakeholder groups including law enforcement agents, national
                  media. Working as a researcher and science communication expert has taught me how
                  to connect biodiversity researchers with national, European and international
                  policies and policy makers. I have co-authored a Science paper about the failures
                  of science-policy dialogue in relation to EU agricultural policy. I have more than
                  11,000 followers on Twitter and am a trained mediator since 2016.
                </p>
                <p className="about-wrapper__info-text">
                  {'Find out more on twitter '}{' '}
                  <a href="https://twitter.com/RosmarieKatrin">
                    <i className="fa fa-twitter fa-inverse" style={{ color: 'black' }} />
                  </a>{' '}
                  or LinkedIn{' '}
                  <a href="https://www.linkedin.com/in/dr-rosmarie-katrin-neumann-397a2286/">
                    <i className="fa fa-linkedin fa-inverse" style={{ color: 'black' }} />
                  </a>
                </p>
                <p className="about-wrapper__info-text">{paragraphThree || ''}</p>
                {resume && (
                  <span className="d-flex mt-3">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta-btn cta-btn--resume"
                      href={resume}
                    >
                      Resume
                    </a>
                  </span>
                )}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
