import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';
import GithubButtons from '../GithubButtons/GithubButtons';

import { githubButtons } from '../../mock/data';

const Footer = () => {
  const { footer } = useContext(PortfolioContext);
  const { networks } = footer;
  const { isEnabled } = githubButtons;

  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <span className="back-to-top">
          <Link to="hero" smooth duration={1000}>
            <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
          </Link>
        </span>
        <div className="social-links">
          {networks &&
            networks.map((network) => {
              const { id, name, url } = network;
              return (
                <a
                  key={id}
                  href={url || ''}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={name}
                >
                  <i className={`fa fa-${name || 'refresh'} fa-inverse`} />
                </a>
              );
            })}
        </div>
        <p>Dr. Rosmarie Katrin Neumann</p>
        <p className="footer__text">
          Wettiner Str. 25, <br /> 04105 Leipzig, Germany
        </p>
        <a href="tel:+4915771982307" className="footer__text">
          Mobile: +49 1577 1982307
        </a>{' '}
        <br />
        <a href="mailto:rosmarie.katrin@impactdialog.com" className="footer__text">
          Mail: rosmarie.katrin@impactdialog.com
        </a>
        <hr />
        <p className="footer__text1">
          © {new Date().getFullYear()} - Designed by{' '}
          <a href="https://wischn.com" target="_blank" rel="noopener noreferrer">
            Annika Wischnewsky
          </a>
        </p>
        {isEnabled && <GithubButtons />}
      </Container>
    </footer>
  );
};

export default Footer;
