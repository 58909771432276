import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';

const Projects = () => {
  const { projects } = useContext(PortfolioContext);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="projects">
      <Container>
        <Fade duration={1000} delay={600}>
          <div className="project-wrapper">
            <Title title="My Offers" />
            <ul id="offers">
              <li>
                <a href="Impact-Training.pdf">
                  Impact training for academics, including 1-to-1 coachings &#8593;
                </a>{' '}
              </li>

              <li>
                <a href="Network-Workshop.pdf">Network workshop for academics &#8593;</a>
              </li>

              <li>
                <a href="Active-Listening-Workshop.pdf">
                  Active listening workshop (not only for academics) &#8593;
                </a>
              </li>

              <li>
                Specialized workshops about social media, personal branding (for academics),
                stakeholder analysis and impact evaluation
              </li>
              <li>
                Coaching - specialization in supporting you to create impact through effective
                communication
              </li>
              <li>Development of communication and impact strategies</li>
            </ul>
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Projects;
