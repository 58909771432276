import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import ReferenceImg from '../Image/ReferenceImg';
import PortfolioContext from '../../context/context';

const Reference = () => {
  const { reference } = useContext(PortfolioContext);
  const { img1, img2, paragraphOne, resume } = reference;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="reference">
      <Container>
        <Title title="References" />
        <Row className="reference-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={700} distance="30px">
              <div className="reference-wrapper__image">
                <ReferenceImg alt="Prof Mark Reed" filename={img1} />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="reference-wrapper__info">
                <p className="reference-wrapper__info-text">
                  “Rosmarie combines research expertise with extensive practical experience to
                  inspire and motivate the people who come to her training. I have enjoyed training
                  alongside Rosi and notice how people warm to her open and honest approach. Her
                  experience and expertise as a mediator and science-policy knowledge broker give
                  her a unique skill set and enable her to illustrate her training with first hand
                  stories from her own experience. I highly recommend Rosi's course and her skills
                  as a trainer, mediator and knowledge broker.”
                </p>
                <p className="reference-wrapper__info-text_hallo">
                  Prof. Mark Reed, Professor of Rural Entrepreneurship and Director at the Natural
                  Capital Challenge Centre at Scotland's Rural College (SRUC),
                  <a href="https://twitter.com/profmarkreed">{' @profmarkreed'}</a>
                </p>
              </div>
            </Fade>
          </Col>
        </Row>
        <Row className="reference-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={700} distance="30px">
              <div className="reference-wrapper__image">
                <ReferenceImg alt="Prof Mark Reed" filename={img2} />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="reference-wrapper__info">
                <p className="reference-wrapper__info-text">
                  “Working in climate protection management, impactful communication is essential
                  for success. Luckily, I was able to train my communication skills very early in my
                  career while attending Rosi’s Impact Training. I now know to set my goals while
                  also keeping my stakeholders’ interests in mind. With her help I learned to look
                  at such communication challenges from new perspectives which enabled me to
                  overcome these seemingly unsolvable difficulties. But the biggest impact for me
                  was when Rosi made me realize how much one can accomplish when working as a team:
                  Rosi showed me that together we have the power to change the world.”
                </p>
                <p className="reference-wrapper__info-text_hallo">
                  Katharina Funk, Climate Change Manager, City of Erlangen,
                  <a href="https://twitter.com/KEFunk">{' @KEFunk'}</a>
                </p>
                <p className="reference-wrapper__info-text">{''}</p>
                {resume && (
                  <span className="d-flex mt-3">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta-btn cta-btn--resume"
                      href={resume}
                    >
                      Resume
                    </a>
                  </span>
                )}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Reference;
