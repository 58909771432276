import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';

const Vision = () => {
  const { vision } = useContext(PortfolioContext);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="vision">
      <Container>
        <Fade bottom duration={1000} delay={700} distance="30px">
          <div className="vision-wrapper">
            <Title title="Vision" />
            <p>
              The essence of <strong> Impact Dialog</strong> is that sustainable impact can only be
              achieved through respectful dialogue. My work is therefore build on three components:{' '}
            </p>
            <ul id="visionul">
              <li>
                Enabling you to <strong>connect</strong> to your inner reasons to achieve impact and
                to select, choose and connect with your relevant actors,{' '}
              </li>
              <li>
                Support you with the development and implementation of strategies enabling you to
                have ongoing <strong>communication</strong> with your target audiences,{' '}
              </li>
              <li>
                Equip you with the relevant tools to maintain long-term,{' '}
                <strong>sustainable</strong> relationships formed and built on trust and respect.
              </li>
            </ul>
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Vision;
